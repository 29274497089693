import {
    HIDE_MESSAGE,
    HIDE_CAPTCHA,
    HIDE_RES_VERIFY_PHONE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SHOW_MESSAGE_SUCCESS,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNIN_SOCIAL,
    SIGNUP_SOCIAL_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ADVANTAGE,
    GET_USER_ADVANTAGE_SUCCESS,
    GET_USER_LOGIN_INFO,
    GET_USER_LOGIN_INFO_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    VERIFY_PHONE,
    VERIFY_PHONE_SUCCESS,
    VERIFY_CAPTCHA,
    VERIFY_CAPTCHA_SUCCESS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_SUCCESS,
    UPDATE_PHONE,
    UPDATE_PHONE_SUCCESS,
    VERIFY_EMAIL_OTP,
    VERIFY_EMAIL_OTP_SUCCESS,
    VERIFY_UPDATE_PHONE,
    VERIFY_UPDATE_PHONE_SUCCESS,
    REGISTER_GARDIENT_USER,
    DELETE_ACCOUNT,
    DEACTIVE_ACCOUNT,
    DELETE_ACCOUNT_VERIFY,
    ACTIVE_ACCOUNT,
    ACTIVE_ACCOUNT_VERIFY
} from 'constants/ActionTypes';

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};
export const getUser = (user) => {
    return {
        type: GET_USER,
        payload: user
    };
};

export const getUserLoginInfo = (user) => {
    return {
        type: GET_USER_LOGIN_INFO,
        payload: user
    };
}

export const getUserAdvantage = (user) => {
    return {
        type: GET_USER_ADVANTAGE,
        payload: user
    };
};
export const userSignInSocial = (user) => {
    return {
        type: SIGNIN_SOCIAL,
        payload: user
    };
};
export const userSignOut = (user) => {
    return {
        type: SIGNOUT_USER,
        payload: user
    };
};

export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const getUserSuccess = (authUser) => {
    return {
        type: GET_USER_SUCCESS,
        payload: authUser
    };
};

export const clearUserData = () => {
    return {
        type: GET_USER_SUCCESS,
        payload: ''
    };
}

export const getUserLoginInfoSuccess = data => {
    return {
        type: GET_USER_LOGIN_INFO_SUCCESS,
        payload: data
    }
}

export const getUserAdvantageSuccess = (authUser) => {
    return {
        type: GET_USER_ADVANTAGE_SUCCESS,
        payload: authUser
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser
    }
};
export const userSignInSocialSuccess = (authUser) => {
    return {
        type: SIGNUP_SOCIAL_SUCCESS,
        payload: authUser
    }
};
export const userSignOutSuccess = (payload) => {
    return {
        type: SIGNOUT_USER_SUCCESS,
        payload
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};

export const showMessageSuccess = (message) => {
    return {
        type: SHOW_MESSAGE_SUCCESS,
        payload: message
    };
};


export const userGoogleSignIn = () => {
    return {
        type: SIGNIN_GOOGLE_USER
    };
};
export const userGoogleSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GOOGLE_USER_SUCCESS,
        payload: authUser
    };
};
export const userFacebookSignIn = () => {
    return {
        type: SIGNIN_FACEBOOK_USER
    };
};
export const userFacebookSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_FACEBOOK_USER_SUCCESS,
        payload: authUser
    };
};
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const userTwitterSignIn = () => {
    return {
        type: SIGNIN_TWITTER_USER
    };
};
export const userTwitterSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_TWITTER_USER_SUCCESS,
        payload: authUser
    };
};
export const userGithubSignIn = () => {
    return {
        type: SIGNIN_GITHUB_USER
    };
};
export const userGithubSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GITHUB_USER_SUCCESS,
        payload: authUser
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideCaptcha = () => {
    return {
        type: HIDE_CAPTCHA,
    };
};
export const hideResVerifyPhone = () => {
    return {
        type: HIDE_RES_VERIFY_PHONE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};
export const userUpdate = (user) => {
    return {
        type: UPDATE_USER,
        payload: user
    };
};
export const userUpdateSuccess = (authUser) => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: authUser
    };
};
export const userRegister = (user) => {
    return {
        type: REGISTER_USER,
        payload: user
    };
};

export const userGuardientRegister = (user) => {
    return {
        type: REGISTER_GARDIENT_USER,
        payload: user
    };
};
export const userRegisterSuccess = (authUser) => {
    return {
        type: REGISTER_USER_SUCCESS,
        payload: authUser
    };
};
export const userVerifyEmail = (user) => {
    return {
        type: VERIFY_EMAIL,
        payload: user
    };
};
export const userVerifyEmailSuccess = (authUser) => {
    return {
        type: VERIFY_EMAIL_SUCCESS,
        payload: authUser
    };
};

export const userVerifyOtp = (user) => {
    return {
        type: VERIFY_OTP,
        payload: user
    };
};
export const userVerifyOtpSuccess = (authUser) => {
    return {
        type: VERIFY_OTP_SUCCESS,
        payload: authUser
    };
};

export const userVerifyPhone = (user) => {
    return {
        type: VERIFY_PHONE,
        payload: user
    };
};

export const userDeleteAccountAction = (user) => {
    return {
        type: DELETE_ACCOUNT,
        payload: user
    };
};

export const userDeleteVerifyPhoneAction = (user) => {
    return {
        type: DELETE_ACCOUNT_VERIFY,
        payload: user
    };
};

// export const userDeleteAccountSuccessAction = (authUser) => {
//     return {
//         type: DELETE_ACCOUNT_SUCCESS,
//         payload: authUser
//     };
// };


export const userDeactiveAccountAction = (user) => {
    return {
        type: DEACTIVE_ACCOUNT,
        payload: user
    };
};

export const userDeactiveVerifyAccountAction = (user) => {
    return {
        type: DELETE_ACCOUNT_VERIFY,
        payload: user
    };
};

// export const userDeactiveAccountSuccessAction = (authUser) => {
//     return {
//         type: DEACTIVE_ACCOUNT_SUCCESS,
//         payload: authUser
//     };
// };


export const userVerifyPhoneSuccess = (authUser) => {
    return {
        type: VERIFY_PHONE_SUCCESS,
        payload: authUser
    };
};

export const userVerifyCaptcha = (user) => {
    return {
        type: VERIFY_CAPTCHA,
        payload: user
    };
};
export const userVerifyCaptchaSuccess = (authUser) => {
    return {
        type: VERIFY_CAPTCHA_SUCCESS,
        payload: authUser
    };
};

export const userUpdateEmail = (user) => {
    return {
        type: UPDATE_EMAIL,
        payload: user
    };
};
export const userUpdateEmailSuccess = (authUser) => {
    return {
        type: UPDATE_EMAIL_SUCCESS,
        payload: authUser
    };
};

export const userVerifyEmailOtp = (user) => {
    return {
        type: VERIFY_EMAIL_OTP,
        payload: user
    };
};
export const userVerifyEmailOtpSuccess = (authUser) => {
    return {
        type: VERIFY_EMAIL_OTP_SUCCESS,
        payload: authUser
    };
};

export const userVerifyUpdatePhone = (user) => {
    return {
        type: VERIFY_UPDATE_PHONE,
        payload: user
    };
};
export const userVerifyUpdatePhoneSuccess = (authUser) => {
    return {
        type: VERIFY_UPDATE_PHONE_SUCCESS,
        payload: authUser
    };
};

export const userUpdatePhone = (user) => {
    return {
        type: UPDATE_PHONE,
        payload: user
    };
};
export const userUpdatePhoneSuccess = (authUser) => {
    return {
        type: UPDATE_PHONE_SUCCESS,
        payload: authUser
    };
};


export const activeAccountAction = (params) => {
    return {
        type: ACTIVE_ACCOUNT,
        payload: params
    };
};

export const verifyActiveAccountAction = (params) => {
    return {
        type: ACTIVE_ACCOUNT_VERIFY,
        payload: params
    };
};