import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from "../firebase/firebase";
import gameService from "services/Game";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  SIGNIN_SOCIAL,
  GET_USER,
  GET_USER_ADVANTAGE,
  GET_USER_LOGIN_INFO,
  UPDATE_USER,
  REGISTER_USER,
  VERIFY_EMAIL,
  VERIFY_OTP,
  VERIFY_PHONE,
  VERIFY_CAPTCHA,
  UPDATE_EMAIL,
  UPDATE_PHONE,
  VERIFY_EMAIL_OTP,
  VERIFY_UPDATE_PHONE,
  REGISTER_GARDIENT_USER,
  DELETE_ACCOUNT,
  DEACTIVE_ACCOUNT,
  DELETE_ACCOUNT_VERIFY,
  DEACTIVE_ACCOUNT_VERIFY,
  ACTIVE_ACCOUNT,
  ACTIVE_ACCOUNT_VERIFY,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  userSignInSocialSuccess,
  hideCaptcha,
} from "actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  getUserSuccess,
  getUserAdvantageSuccess,
  userUpdateSuccess,
  userRegisterSuccess,
  userVerifyEmailSuccess,
  userVerifyOtpSuccess,
  userVerifyPhoneSuccess,
  userVerifyCaptchaSuccess,
  userUpdateEmailSuccess,
  userUpdatePhoneSuccess,
  userVerifyEmailOtpSuccess,
  userVerifyUpdatePhoneSuccess,
  getUserLoginInfoSuccess,
} from "../actions/Auth";

import {
  getLoggedGamesFromStorage,
  setLoggedGamesToStorage,
} from "util/Common";
import { history } from "../store";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithEmailPasswordRequest = async (
  email,
  password,
  mapID,
  appID,
  countryID
) =>
  await gameService
    .fetchLoginWithEmail(email, password, mapID, appID, countryID)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const signInUserWithSocialRequest = async (
  loginTypeName,
  gameID,
  mapID,
  countryID
) =>
  await gameService
    .fetchLoginWithSocialNetwork(loginTypeName, gameID, mapID, countryID)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const getUserWithGameIDUserIDRequest = async (gameID, userID) =>
  await gameService
    .fetchUser(gameID, userID)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const fetchUserLoginInfo = async (gameID, userID) =>
  await gameService
    .fetchUserLoginInfo(gameID, userID)
    .then((res) => res.json())
    .then((data) => data);

const getUserAdvatageRequest = async (gameID, userID, type) =>
  await gameService
    .fetchUserAdvantage(gameID, userID, type)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const updateUserRequest = async (gameID, userID, params) =>
  await gameService
    .fetchUpdateUser(gameID, userID, params)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const registerUserRequest = async (gameID, userID, params) =>
  await gameService
    .fetchRegister(gameID, userID, params)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const registerGuardientUserRequest = async (gameID, userID, params) =>
  await gameService
    .fetchGuardianRegister(gameID, userID, params)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const logoutRequest = async (logoutArr) =>
  await gameService
    .fetchLogout(logoutArr)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const verifyEmailRequest = async (gameID, userID, test) =>
  await gameService
    .fetchVerifyEmail(gameID, userID, test)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const verifyPhoneRequest = async (gameID, userID, otp, test) =>
  await gameService
    .fetchVerifyPhone(gameID, userID, otp, test)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

// Delete account step 1
const deleteAccountVerifyCaptchaRequest = async (gameID, userID, captcha, test, isOtp) =>
    await gameService
      .fetchDeleteAccountVerifyCaptcha(gameID, userID, captcha, test, isOtp)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => error);    

// Deactive account step 2
const deleteAccountRequest = async (gameID, userID, otp, test) =>
  await gameService
    .fetchDeleteAccount(gameID, userID, otp, test)
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return Promise.reject('Response not ok with status ' + response.status);
      }
    })
    .then((data) => data)
    .catch((error) => error);  
    


// Deactive account step 1    
const deactiveAccountVerifyCaptchaRequest = async (gameID, userID, captcha, test, isOtp) =>
    await gameService
      .fetchDeactiveAccountVerifyCaptcha(gameID, userID, captcha, test, isOtp)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => error);  

// Deactive account step 2
const deactiveAccountRequest = async (gameID, userID, otp, test) =>
    await gameService
      .fetchDeactiveAccount(gameID, userID, otp, test)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject('Response not ok with status ' + response.status);
        }
      })
      .then((data) => data)
      .catch((error) => error);    

const cancelDeactiveAccountVerifyCaptchaRequest = async (gameID, userID, captcha, test, isOtp) =>
    await gameService
      .fetchCancelDeactiveAccountVerifyCaptcha(gameID, userID, captcha, test, isOtp)
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => error);  

const cancelDeactiveAccountRequest = async (gameID, userID, otp, test) =>
      await gameService
        .fetchCancelDeactiveAccount(gameID, userID, otp, test)
        .then((response) => response.json())
        .then((data) => data)
        .catch((error) => error);  
        
const cancelDeleteAccountVerifyCaptchaRequest = async (gameID, userID, captcha, test, isOtp) =>
        await gameService
          .fetchCancelDeleteAccountVerifyCaptcha(gameID, userID, captcha, test, isOtp)
          .then((response) => response.json())
          .then((data) => data)
          .catch((error) => error);  
    
const cancelDeleteAccountRequest = async (gameID, userID, otp, test) =>
          await gameService
            .fetchCancelDeleteAccount(gameID, userID, otp, test)
            .then((response) => response.json())
            .then((data) => data)
            .catch((error) => error);  
                    
     

const verifyCaptchaRequest = async (gameID, userID, captcha, test, isOtp) =>
  await gameService
    .fetchVerifyCaptcha(gameID, userID, captcha, test, isOtp)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const updateEmailRequest = async (
  gameID,
  userID,
  captcha,
  ppID,
  oldEmail,
  newEmail,
  phone
) =>
  await gameService
    .fetchUpdateEmail(gameID, userID, captcha, ppID, oldEmail, newEmail, phone)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const updatePhoneRequest = async (
  gameID,
  userID,
  captcha,
  currentPhone,
  newPhone,
  ppID,
  email
) =>
  await gameService
    .fetchUpdatePhone(
      gameID,
      userID,
      captcha,
      currentPhone,
      newPhone,
      ppID,
      email
    )
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const verifyEmailOtpRequest = async (gameID, userID, captcha, otp) =>
  await gameService
    .fetchVerifyEmailOtp(gameID, userID, captcha, otp)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const verifyUpdatePhoneRequest = async (gameID, userID, otp, captcha) =>
  await gameService
    .fetchVerifyUpdatePhone(gameID, userID, otp, captcha)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then((authUser) => authUser)
    .catch((error) => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage("Your request has been canceled."));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password, mapID, appID, countryID } = payload;

  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password,
      mapID,
      appID,
      countryID
    );
    if (signInUser.returnCode !== 0) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      yield put(userSignInSuccess(signInUser.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getUserWithGameIdAndUserId({ payload }) {
  const { gameID, userID, type } = payload;
  try {
    const signInUser = yield call(
      getUserWithGameIDUserIDRequest,
      gameID,
      userID,
      type
    );
    if (
      signInUser.returnCode !== 1 &&
      signInUser.returnCode !== -5 &&
      signInUser.returnCode !== -405
    ) {
      yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(getUserSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getUserLoginInfo({ payload }) {
  const { gameID, userID } = payload;
  try {
    const response = yield call(fetchUserLoginInfo, gameID, userID);
    if (response.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(getUserLoginInfoSuccess(response.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getUserAdvatageWithType({ payload }) {
  const { gameID, userID, type } = payload;
  try {
    const signInUser = yield call(getUserAdvatageRequest, gameID, userID, type);
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(getUserAdvantageSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* activeAccountVerifyCaptchaSaga({ payload }) {
  const { gameID, userID, captcha, test, isOtp, action } = payload;
  try {
    // action == "cancel_delete || cancel_deactive"
    const signInUser = yield call(
      action === "cancel_delete" ? cancelDeleteAccountVerifyCaptchaRequest : cancelDeactiveAccountVerifyCaptchaRequest,
      gameID,
      userID,
      captcha,
      test,
      isOtp
    );
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
      yield put(hideCaptcha());
    } else {
      yield put(userVerifyCaptchaSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* activeUserSaga({ payload }) {
  const { gameID, userID, otp, test, action } = payload;
  try {
    yield put(userVerifyPhoneSuccess(""));
    const signInUser = yield call(
      action === "cancel_delete" ? cancelDeleteAccountRequest : cancelDeactiveAccountRequest,
      gameID,
      userID,
      otp,
      test
    );
    yield put(userVerifyPhoneSuccess(signInUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* updateUserWithGameIDAndUserID({ payload }) {
  const { gameID, userID, params } = payload;
  try {
    const signInUser = yield call(updateUserRequest, gameID, userID, params);
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(userUpdateSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* registerUserWithGameIDAndUserID({ payload }) {
  const { gameID, userID, params } = payload;

  try {
    const signInUser = yield call(registerUserRequest, gameID, userID, params);
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(userRegisterSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* registerGuardientUserWithGameIDAndUserID({ payload }) {
  const { gameID, userID, params } = payload;
  
  try {
    const signInUser = yield call(registerGuardientUserRequest, gameID, userID, params);
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(userRegisterSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* verifyEmailWithGameIDAndUserID({ payload }) {
  const { gameID, userID, test } = payload;
  try {
    const signInUser = yield call(verifyEmailRequest, gameID, userID, test);
    if (signInUser.returnCode !== 1) {
      // yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(userVerifyEmailSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* verifyOtpWithGameIDAndUserID({ payload }) {
  const { gameID, userID, otp, test } = payload;
  try {
    const signInUser = yield call(
      verifyPhoneRequest,
      gameID,
      userID,
      otp,
      test
    );
    if (signInUser.returnCode !== 1) {
      // yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(userVerifyOtpSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* verifyPhoneWithGameIDAndUserID({ payload }) {
  const { gameID, userID, otp, test } = payload;
  try {
    yield put(userVerifyPhoneSuccess(""));
    const signInUser = yield call(
      verifyPhoneRequest,
      gameID,
      userID,
      otp,
      test
    );
    yield put(userVerifyPhoneSuccess(signInUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* verifyCaptchaWithGameIDAndUserID({ payload }) {
  const { gameID, userID, captcha, test, isOtp } = payload;
  try {
    const signInUser = yield call(
      verifyCaptchaRequest,
      gameID,
      userID,
      captcha,
      test,
      isOtp
    );
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
      yield put(hideCaptcha());
    } else {
      yield put(userVerifyCaptchaSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* deleteAccountVerifyCaptchaSaga({ payload }) {
  const { gameID, userID, captcha, test, isOtp, action } = payload;
  try {
    // action == delete || deactive
    const signInUser = yield call(
      action === "deactive" ? deactiveAccountVerifyCaptchaRequest : deleteAccountVerifyCaptchaRequest,
      gameID,
      userID,
      captcha,
      test,
      isOtp
    );
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
      yield put(hideCaptcha());
    } else {
      yield put(userVerifyCaptchaSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* deleteAccountSaga({ payload }) {
  const { gameID, userID, otp, test, action } = payload;
  try {
    yield put(userVerifyPhoneSuccess(""));
    const signInUser = yield call(
      action === "deactive" ? deactiveAccountRequest : deleteAccountRequest,
      gameID,
      userID,
      otp,
      test
    );
    yield put(userVerifyPhoneSuccess(signInUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* deactiveAccountVerifyCaptchaSaga({ payload }) {
  const { gameID, userID, captcha, test, isOtp } = payload;
  try {
    const signInUser = yield call(
      deleteAccountVerifyCaptchaRequest,
      gameID,
      userID,
      captcha,
      test,
      isOtp
    );
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
      yield put(hideCaptcha());
    } else {
      yield put(userVerifyCaptchaSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* deactiveAccountSaga({ payload }) {
  const { gameID, userID, otp, test } = payload;
  try {
    yield put(userVerifyPhoneSuccess(""));
    const signInUser = yield call(
      deactiveAccountRequest,
      gameID,
      userID,
      otp,
      test
    );
    yield put(userVerifyPhoneSuccess(signInUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* updateEmailWithGameIDAndUserID({ payload }) {
  const { gameID, userID, captcha, ppID, oldEmail, newEmail, phone } = payload;
  try {
    const signInUser = yield call(
      updateEmailRequest,
      gameID,
      userID,
      captcha,
      ppID,
      oldEmail,
      newEmail,
      phone
    );
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
      if (signInUser.returnCode === -12) {
        yield put(hideCaptcha());
      }
    } else {
      yield put(userUpdateEmailSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* updatePhoneWithGameIDAndUserID({ payload }) {
  const {
    gameID,
    userID,
    captcha,
    currentPhone,
    newPhone,
    ppID,
    email,
  } = payload;
  try {
    const signInUser = yield call(
      updatePhoneRequest,
      gameID,
      userID,
      captcha,
      currentPhone,
      newPhone,
      ppID,
      email
    );
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
      if (signInUser.returnCode === -12) {
        yield put(hideCaptcha());
      }
    } else {
      yield put(userUpdatePhoneSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* verifyEmailOtpWithGameIDAndUserID({ payload }) {
  const { gameID, userID, captcha, otp } = payload;
  try {
    yield put(userVerifyEmailOtpSuccess(""));
    const signInUser = yield call(
      verifyEmailOtpRequest,
      gameID,
      userID,
      captcha,
      otp
    );
    yield put(userVerifyEmailOtpSuccess(signInUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* verifyUpdatePhoneWithGameIDAndUserID({ payload }) {
  const { gameID, userID, otp, captcha } = payload;
  try {
    yield put(userVerifyUpdatePhoneSuccess(""));
    const signInUser = yield call(
      verifyUpdatePhoneRequest,
      gameID,
      userID,
      otp,
      captcha
    );
    yield put(userVerifyUpdatePhoneSuccess(signInUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithSocial({ payload }) {
  const { loginTypeName, gameID, mapID, countryID } = payload;
  try {
    const signInUser = yield call(
      signInUserWithSocialRequest,
      loginTypeName,
      gameID,
      mapID,
      countryID
    );
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      yield put(userSignInSocialSuccess(signInUser.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut({ payload }) {
  const { gameID, userID, type } = payload;
  let logoutArr = [];
  const appIDs = getLoggedGamesFromStorage();
  let idLogout = gameID;
  try {

    if (type === "one") {
      const idx = appIDs.map((e) => e.gameID).indexOf(gameID);
      if (idx !== -1) {
        appIDs.splice(idx, 1);
        setLoggedGamesToStorage(appIDs);
      }
      logoutArr = [{ gameID: gameID, userID: userID }];
    } else {
      setLoggedGamesToStorage([]);
      idLogout = "all";
      logoutArr = appIDs;
    }
    const signInUser = yield call(logoutRequest, logoutArr);
    if (signInUser.returnCode !== 1) {
      yield put(showAuthMessage(signInUser.returnMessage));
    } else {
      yield put(userSignOutSuccess(idLogout));
      if (type === 'one') {
        history.push('/login')
      }
    }

  } catch (error) {
    console.log("error", error)
    yield put(showAuthMessage(error));
  }
}



export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInSocial() {
  yield takeEvery(SIGNIN_SOCIAL, signInUserWithSocial);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* getUser() {
  yield takeEvery(GET_USER, getUserWithGameIdAndUserId);
}

export function* getLoginInfo() {
  yield takeEvery(GET_USER_LOGIN_INFO, getUserLoginInfo);
}

export function* getUserAdvantage() {
  yield takeEvery(GET_USER_ADVANTAGE, getUserAdvatageWithType);
}

export function* updateUser() {
  yield takeEvery(UPDATE_USER, updateUserWithGameIDAndUserID);
}

export function* registerUser() {
  yield takeEvery(REGISTER_USER, registerUserWithGameIDAndUserID);
}

export function* registerGuardientUser() {
  yield takeEvery(REGISTER_GARDIENT_USER, registerGuardientUserWithGameIDAndUserID);
}

export function* verifyEmail() {
  yield takeEvery(VERIFY_EMAIL, verifyEmailWithGameIDAndUserID);
}

export function* verifyOtp() {
  yield takeEvery(VERIFY_OTP, verifyOtpWithGameIDAndUserID);
}

export function* verifyPhone() {
  yield takeEvery(VERIFY_PHONE, verifyPhoneWithGameIDAndUserID);
}

export function* verifyCaptcha() {
  yield takeEvery(VERIFY_CAPTCHA, verifyCaptchaWithGameIDAndUserID);
}

export function* updateEmail() {
  yield takeEvery(UPDATE_EMAIL, updateEmailWithGameIDAndUserID);
}

export function* updatePhone() {
  yield takeEvery(UPDATE_PHONE, updatePhoneWithGameIDAndUserID);
}

export function* verifyEmailOtp() {
  yield takeEvery(VERIFY_EMAIL_OTP, verifyEmailOtpWithGameIDAndUserID);
}

export function* verifyUpdatePhone() {
  yield takeEvery(VERIFY_UPDATE_PHONE, verifyUpdatePhoneWithGameIDAndUserID);
}


export function* deleteAccount() {
  yield takeLatest(DELETE_ACCOUNT, deleteAccountSaga);
}

export function* deleteAccountVerify() {
  yield takeLatest(DELETE_ACCOUNT_VERIFY, deleteAccountVerifyCaptchaSaga);
}

export function* deactiveAccount() {
  yield takeEvery(DEACTIVE_ACCOUNT, deactiveAccountSaga);
}

export function* activeAccount() {
  yield takeEvery(ACTIVE_ACCOUNT, activeUserSaga);
}

export function* verifyActiveAccount() {
  yield takeEvery(ACTIVE_ACCOUNT_VERIFY, activeAccountVerifyCaptchaSaga);
}

export function* deactiveAccountVerify() {
  yield takeEvery(DEACTIVE_ACCOUNT_VERIFY, deactiveAccountVerifyCaptchaSaga);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
    fork(signInSocial),
    fork(getUser),
    fork(getLoginInfo),
    fork(getUserAdvantage),
    fork(updateUser),
    fork(registerUser),
    fork(registerGuardientUser),
    fork(verifyEmail),
    fork(verifyPhone),
    fork(verifyCaptcha),
    fork(verifyOtp),
    fork(updateEmail),
    fork(updatePhone),
    fork(verifyEmailOtp),
    fork(verifyUpdatePhone),

    fork(deleteAccount),
    fork(deleteAccountVerify),
    fork(deactiveAccount),
    fork(deactiveAccountVerify),

    fork(activeAccount),
    fork(verifyActiveAccount)
  ]);
}
