import {
  FETCH_ALL_GAME_SUCCESS,
  FETCH_GAME_SUCCESS,
  ON_CLICK_GAME,
  FECTH_GAME_FAILED,
  SET_GAME_TRANSFER,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: "",
  games: [],
  mobileGames: [],
  desktopGames: [],
  gameNames: [],
  gameData: [],
  loginMethods: [],
  gameID: "",
  faviconURL: "",
  error: null,
  listGameAllowTransfer: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_GAME_SUCCESS: {
      return {
        ...state,
        loader: false,
        games: action.payload,
        gameNames: action.payload.map((e) => {
          return {
            shopfrontName: e.shopfrontName,
            shopfrontID: e.shopfrontID,
            platform: e.platform,
          };
        }),
        mobileGames: action.payload.filter((e) => e.platform === "mobile"),
        desktopGames: action.payload.filter((e) => e.platform === "pc"),
        faviconURL: null,
      };
    }
    case FETCH_GAME_SUCCESS: {
      return {
        ...state,
        loader: false,
        gameData: [action.payload],
        loginMethods: action.payload.loginConfig.loginMethods,
        faviconURL: action.payload.images.favicon,
      };
    }
    case FECTH_GAME_FAILED: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case ON_CLICK_GAME: {
      return {
        ...state,
        gameID: action.payload,
      };
    }
    case SET_GAME_TRANSFER: {
      return {
        ...state,
        listGameAllowTransfer: action.payload,
      };
    }
    default:
      return state;
  }
};
