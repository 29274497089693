import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import gameSagas from './Game';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        gameSagas()
    ]);
}
