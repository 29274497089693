import React from "react";
import styled from "styled-components";

function TransferGuide() {
  return (
    <GuideContainer>
      <h1 className="title">HƯỚNG DẪN CHUYỂN ĐỔI TÀI KHOẢN SANG ZINGID</h1>
      <Step>
        <p className="step-title">
          <strong>I. Giới Thiệu</strong>
        </p>
        <p>
          Tính năng <strong>“Chuyển đổi tài khoản Zing ID”</strong>, nhằm mục
          đích hỗ trợ khách hàng chuyển đổi từ kênh đăng nhập sang kênh
          đăng nhập Zing ID.
        </p>
        <div className="images">
          <div className="left">
            <img src="/guides/1.1.png" alt="step1.1" />
            <p className="img-desc">
              Màn hình https://account.id.zing.vn/ trên PC
            </p>
          </div>
          <div className="right">
            <img src="/guides/1.2.png" alt="step1.2" />
            <p className="img-desc">
              Màn hình https://account.id.zing.vn/ trên Mobile
            </p>
          </div>
        </div>
      </Step>
      <Step>
        <p className="step-title">
          <strong>II. Điều kiện chuyển đổi </strong>
        </p>
        <p>
          Tài khoản game sử dụng kênh đăng nhập cần chuyển đổi đã hoàn
          thành khai báo thông tin cá nhân tại trang{" "}
          <a href="https://account.id.zing.vn" target="_blank">
            https://account.id.zing.vn
          </a>
        </p>
        <p>
          Tài khoản Zing ID dùng để chuyển đổi qua sẽ được tạo mới hoàn toàn,
          không dùng tài khoản Zing ID có sẵn.
        </p>
      </Step>
      <Step>
        <p className="step-title">
          <strong>III. Cách thức thực hiện </strong>
        </p>
        <p>
          <span className="blue">Bước 1:</span> Truy cập vào link chuyển đổi
          bằng tài khoản muốn chuyển đổi để tiến hành khai báo thông tin cá
          nhân và xác nhận thông tin bảo vệ.
        </p>
        <p>
          <span className="blue">Bước 2:</span> Khai báo thông tin cá nhân và
          xác nhận thông tin bảo vệ.
        </p>
        <p>
          <b>2.1</b> Khai báo thông tin cá nhân (nếu tài khoản đã khai báo trước
          đó sẽ cần xác nhận thông tin bảo vệ).
        </p>
        <p>
          <b>
            <i>
              * Lưu ý: Email và số điện thoại là thông tin dùng để bảo vệ tài
              khoản. Vui lòng sử dụng thông tin chính xác.
            </i>
          </b>
        </p>
        <div className="images">
          <div className="left">
            <img src="/guides/2.1.png" alt="step1.1" />
            <p className="img-desc">Màn hình khai báo thông tin trên PC</p>
          </div>
          <div className="right">
            <img src="/guides/2.2.png" alt="step1.2" />
            <p className="img-desc">Màn hình khai báo thông tin trên Mobile</p>
          </div>
        </div>
        <p>
          <b> 2.2</b> Xác nhận thông tin bảo vệ (ưu tiên phương thức bảo vệ bằng
          số điện thoại).
        </p>
        <p>
          Nếu tài khoản đã xác nhận thông tin bảo vệ sẽ được chuyển sang bước 3.
        </p>
        <p>
          <b>* Lưu ý: </b>
          <i>
            Nếu không tìm thấy email trong hộp thư vui, bạn vui lòng kiểm tra
            lại trong hộp thư Spam.
          </i>
        </p>
        <p>
          Nếu vẫn không tìm thấy email/sms, vui lòng thực hiện lại sau hoặc sử
          dụng các cách còn lại để xác nhận thông tin bảo vệ.
        </p>
        <div className="images">
          <div className="left">
            <img src="/guides/3.1.png" alt="step1.1" />
            <p className="img-desc">
              Màn hình tab <b>“Chuyển đổi tài khoản Zing ID"</b> trên PC
            </p>
          </div>
          <div className="right">
            <img src="/guides/3.2.png" alt="step1.2" />
            <p className="img-desc">
              Màn hình tab <b>“Chuyển đổi tài khoản Zing ID"</b> trên Mobile
            </p>
          </div>
        </div>
        <div className="images">
          <div className="left">
            <img src="/guides/3.3.png" alt="step3.3" />
            <p className="img-desc">
              Màn hình tab <b>“Thông tin bảo vệ"</b> trên PC
            </p>
          </div>
          <div className="right">
            <img src="/guides/3.4.png" alt="step1.2" />
            <p className="img-desc">
              Màn hình tab <b>“Thông tin bảo vệ"</b> trên Mobile
            </p>
          </div>
        </div>
        <div className="images">
          <div className="left">
            <img src="/guides/3.5.png" alt="step3.3" />
            <p className="img-desc">Màn hình tab recapcha trên PC</p>
          </div>
          <div className="right">
            <img src="/guides/3.6.png" alt="step1.2" />
            <p className="img-desc">Màn hình tab recapcha trên Mobile</p>
          </div>
        </div>
        <div className="images">
          <div className="left">
            <img src="/guides/3.7.png" alt="step3.3" />
            <p className="img-desc">Màn hình nhập đúng mã OTP trên PC</p>
          </div>
          <div className="right">
            <img src="/guides/3.8.png" alt="step1.2" />
            <p className="img-desc">Màn hình nhập đúng mã OTP trên Mobile</p>
          </div>
        </div>
        <p>
          <b>*Lưu ý:</b> Trường hợp thông tin khai báo chưa chính xác vui lòng
          bấm vào nút <b>“Cập nhật”</b> để cập nhật thông tin.
        </p>
        <p>
          Nếu bạn không sử dụng được số điện thoại hoặc email đã khai báo trước
          đó, bạn có thể click chọn link điều hướng về trang Hỗ trợ, và gửi yêu
          cầu.
        </p>
        <div className="images">
          <div className="left">
            <img src="/guides/3.9.png" alt="step1.1" />
            <p className="img-desc">
              Màn hình tab <b>“Thông tin bảo vệ"</b> trên PC
            </p>
          </div>
          <div className="right">
            <img src="/guides/3.10.png" alt="step1.2" />
            <p className="img-desc">
              Màn hình tab <b>“Thông tin bảo vệ"</b> trên Mobile
            </p>
          </div>
        </div>
        <p>
          <span className="blue">Bước 3:</span> Khởi tạo thông tin tài khoản
          đăng nhập Zing ID tại tab Chuyển đổi tài khoản Zing ID.
        </p>
        <p>
          <b>3.1</b> Xác thực tài khoản (ưu tiên phương thức xác thực tài khoản
          qua số điện thoại, mỗi số điện thoại có thể nhận tối đa 20 sms/ngày/số
          điện thoại, thời gian gửi lại sau mỗi lần là 3 phút).
        </p>
        <div className="images">
          <div className="left">
            <img src="/guides/3.11.png" alt="step1.1" />
            <p className="img-desc">
              Màn hình tab <b>“Chuyển đổi tài khoản Zing ID"</b> sau khi đã xác
              nhận thông tin trên PC
            </p>
          </div>
          <div className="right">
            <img src="/guides/3.12.png" alt="step1.2" />
            <p className="img-desc">
              Màn hình tab <b>“Chuyển đổi tài khoản Zing ID"</b> sau khi đã xác
              nhận thông tin trên Mobile
            </p>
          </div>
        </div>
        <div className="images">
          <div className="left">
            <img src="/guides/3.13.png" alt="step1.1" />
            <p className="img-desc">Màn hình xác thực thông tin trên PC</p>
          </div>
          <div className="right">
            <img src="/guides/3.14.png" alt="step1.2" />
            <p className="img-desc">Màn hình xác thực thông tin trên Mobile</p>
          </div>
        </div>
        <p>
          <b>3.2</b> Tại tab Chuyển đổi tài khoản Zing ID điền thông tin chuyển
          đổi, bạn sẽ được tạo mới tài khoản Zing ID theo thông tin bạn đăng ký,
          thông tin tài khoản game sẽ được chuyển đổi hoàn toàn sang Zing ID mới
          này.
        </p>
        <div className="images">
          <div className="left">
            <img src="/guides/3.15.png" alt="step1.1" />
            <p className="img-desc">
              Màn hình nhập thông tin chuyển đổi trên PC
            </p>
          </div>
          <div className="right">
            <img src="/guides/3.16.png" alt="step1.2" />
            <p className="img-desc">
              Màn hình nhập thông tin chuyển đổi trên Mobile
            </p>
          </div>
        </div>
        <p>
          <span className="blue">Bước 4:</span> Xác nhận thực hiện chuyển đổi
        </p>
        <p>
          Bạn vui lòng đọc kỹ nội dung điều khoản và đồng ý việc chuyển đổi kênh
          đăng nhập tài khoản sang Zing ID và không thể sử dụng phương thức đăng
          nhập để truy cập tài khoản ingame.
        </p>
        <p>
          Bấm vào nút <b>“Chuyển đổi ngay”</b> sau khi kiểm tra tất cả thông tin
          tài khoản là chính xác và đã xác thực thông tin qua email/số điện
          thoại.
        </p>

        <div className="images">
          <div className="left">
            <img src="/guides/3.17.png" alt="step1.1" />
            <p className="img-desc">Màn hình xác nhận chuyển đổi trên PC</p>
          </div>
          <div className="right">
            <img src="/guides/3.18.png" alt="step1.2" />
            <p className="img-desc">Màn hình xác nhận chuyển đổi trên Mobile</p>
          </div>
        </div>
        <div className="images">
          <div className="left">
            <img src="/guides/3.19.png" alt="step1.1" />
            <p className="img-desc">
              Màn hình thông báo chuyển đổi thành công trên PC
            </p>
          </div>
          <div className="right">
            <img src="/guides/3.20.png" alt="step1.2" />
            <p className="img-desc">
              Màn hình thông báo chuyển đổi thành công trên Mobile
            </p>
          </div>
        </div>
        <p>
          Hệ thống sẽ gửi tin nhắn thông báo chuyển đổi tài khoản thành công
          (qua SMS).
        </p>
        <div className="images" style={{ justifyContent: "center" }}>
          <div className="left">
            <img src="/guides/3.21.png" alt="step1.2" />
            <p className="img-desc">Tin nhắn thông báo chuyển đổi thành công</p>
          </div>
        </div>
        <p>
          <span className="blue">Bước 5:</span> Kiểm tra lại thông tin cá nhân
          tại trang{" "}
          <a href="https://id.zing.vn" target="_blank">
            https://id.zing.vn
          </a>
        </p>
        <p>
          <span className="blue">Bước 6:</span> Cập nhật thông tin cá nhân tại
          trang id.zing.vn (nếu cần){" "}
          <a href="https://id.zing.vn" target="_blank">
            https://id.zing.vn
          </a>
        </p>
        <p>
          <span className="blue">Bước 7:</span> Đăng nhập vào game bằng Zing ID
          vừa chuyển đổi để kiểm tra thông tin nhân vật.
        </p>
      </Step>
      <Step>
        <p className="step-title">
          <strong>IV. Lưu ý</strong>
        </p>
        <p>
          Sau khi thực hiện chuyển đổi kênh đăng nhập sang Zing ID, tài
          khoản sẽ không còn thông tin nhân vật ingame, vui lòng sử dụng
          đúng tài khoản Zing ID đã thực hiện chuyển đổi để đăng nhập game.
        </p>
        <p>
          Hệ thống sẽ ưu tiên phương thức xác nhận/xác thực bằng số điện thoại.
        </p>
        <p>Giới hạn SMS là 20 sms/số điện thoại/ngày.</p>
        <p>Giới hạn email nhận là 20 OTP/email/ngày</p>
        <p>Thời gian hiệu lực của OTP là 30 phút</p>
        <p>
          Nếu không tìm thấy email trong hộp thư, bạn vui lòng kiểm tra trong
          hộp thư Spam.
        </p>
        <p>
          Nếu vẫn không tìm thấy email, vui lòng thực hiện lại sau hoặc sử dụng
          các phương thức khác để xác nhận/xác thực/lấy lại mật khẩu.
        </p>
      </Step>
    </GuideContainer>
  );
}

export default TransferGuide;

const GuideContainer = styled.div`
  max-width: 610px;
  margin: 140px auto 50px;
  padding-bottom: 30px;
  .title {
    font-size: 24px;
    text-align: center;
    line-height: 30px;
    padding: 0 60px;
    font-weight: bold;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 700px) {
    margin: 100px auto 20px;

    max-width: 90%;
    /*padding: 0 25px;*/
    .title {
      padding: 0;
    }
  }
`;
const Step = styled.div`
  font-size: 14px;
  .blue {
    color: #2f80ed;
  }
  li {
    margin: 0 0 16px 20px;
  }
  .step-title {
    font-size: 18px;
  }

  .images {
    display: flex;
    gap: 12px;
    .left {
      img {
        width: 424px;
        margin-bottom: 10px;
      }
    }
    .right {
      img {
        width: 174px;
        margin-bottom: 10px;
      }
    }
    .img-desc {
      text-align: center;
      font-size: 12px;
      color: #989898;
      line-height: 16px;
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 700px) {
    .images {
      flex-direction: column;
      text-align: center;
    }
  }
  @media screen and (max-width: 450px) {
    .images {
      .left {
        img {
          width: 100%;
        }
      }
      .right {
        img {
          width: 100%;
          max-width: 130px;
        }
      }
    }
  }
`;
