import React, { Component, lazy, Suspense } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import "assets/vendors/style";
import "styles/user-info.css";
import Favicon from "react-favicon";
import defaultTheme from "./themes/defaultTheme";
import moment from "moment";
import "moment/locale/vi";
import Header from "app/routes/UserInfo/Layout/Header";
import TransferGuide from "./TransferGuide";

import {
  getLoggedGamesFromStorage,
  setLoggedGamesToStorage,
} from "util/Common";
import { setNavigateParams } from "actions/Navigate";

const EmailConfirmResult = lazy(() =>
  import("../app/routes/EmailConfirmResult")
);
const TransferResult = lazy(() =>
  import("../app/routes/UserInfo/Page/TransferResult")
);
const MainApp = lazy(() => import("../app"));
const Login = lazy(() => import("./Login"));
const HomePage = lazy(() => import("./HomePage"));

const RestrictedRoute = ({ component: Component, isLogged, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLogged ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  constructor() {
    super();
    this.state = {
      defaultDatePickerLocale: "vi",
    };
  }

  setLocalStore = (payload) => {
    const { gameID, userID } = payload;
    const appIDs = getLoggedGamesFromStorage();

    appIDs.push({ gameID, userID });
    setLoggedGamesToStorage(appIDs);
  };

  checkLogged = () => {
    const { gameID: navGameID, userID: navUserID, query } = this.props;

    let { gameID, userID } = query;

    if (!gameID || !userID) {
      gameID = navGameID;
      userID = navUserID;
    }

    const appIDs = getLoggedGamesFromStorage();

    const findItem = appIDs.find((item) => {
      return item["gameID"] === gameID && item["userID"] === userID;
    });

    if (findItem) {
      return true;
    }

    return false;
  };

  componentWillMount() {
    const { query, setNavigateParams } = this.props;
    const { gameID, userID } = query;

    if (gameID && userID) {
      this.setLocalStore({ gameID, userID });
      setNavigateParams({
        gameID,
        userID,
      });
    }

    this.checkLogged();

    // Set default locale for moment
    moment.locale(this.state.defaultDatePickerLocale);
  }

  render() {
    const { match, faviconURL, pathname } = this.props;

    const applyTheme = createMuiTheme(defaultTheme);

    return (
      <MuiThemeProvider theme={applyTheme}>
        <Favicon
          url={
            faviconURL ? faviconURL : process.env.PUBLIC_URL + "/favicon.ico"
          }
        />
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={this.state.defaultDatePickerLocale}
        >
          <div className="appMain" versionRelease="123">
            <Suspense fallback={null}>
              {["/", "/transfer-guide", "/transfer-guide/"].includes(
                pathname
              ) ? (
                <Header hideUserInfo={true} />
              ) : (
                ""
              )}
              <Switch>
                <Route path="/" exact component={HomePage} />
                <RestrictedRoute
                  path={`${match.url}app`}
                  isLogged={this.checkLogged()}
                  component={MainApp}
                />
                <Route path="/email-confirm" component={EmailConfirmResult} />
                <Route path="/transfer-result" component={TransferResult} />
                <Route path="/transfer-guide" exact component={TransferGuide} />
                <Route path="/login" component={Login} />
                <Route component={() => <Redirect to="/" />} />
              </Switch>
            </Suspense>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ game, navigateParams, router }) => {
  const {
    location: { query, pathname },
  } = router;
  const { faviconURL } = game;
  const { gameID, userID } = navigateParams;
  return {
    gameID,
    faviconURL,
    query,
    userID,
    pathname,
  };
};

export default connect(mapStateToProps, { push, setNavigateParams })(App);
