/**
 * Get age limit by game
 * @param {Array} gameData 
 * @param {String} gameId
 * @return {Integer}
 */
const getAgeLimitedFromGameData = (gameData, gameId) => {
    let result = null;
    if (Array.isArray(gameData)) {
        const findItem = gameData.find(item => item.appID === gameId);
        if (findItem) {
            result = findItem['shopfrontConfig']['ageLimitAllowed'];
        }
    }
    return parseInt(result);
};

/**
 * Set logged game info to local storage
 * @param {Array} data
 */
const setLoggedGamesToStorage = (data) => {
    if (Array.isArray(data) && data.length > 0) {
        const filterObj = data.reduce((rev, item) => {
            (rev[item['gameID']] = rev[item['gameID']] || []).push(item);
            return rev;
        }, {});

        const filterData = Object.keys(filterObj).map((item) => {
            return filterObj[item].pop();
        });

        localStorage.setItem('app_ids', JSON.stringify(filterData));
        
    } else {
        localStorage.setItem('app_ids', JSON.stringify([]));
    }
};

/**
 * Get logged games info from local storage
 * @return {Array}
 */
const getLoggedGamesFromStorage = () => {
    const loggedGamesInfo = localStorage.getItem('app_ids');
    let resultData = [];
    try {
        resultData = JSON.parse(loggedGamesInfo);
    } catch (error) {
        return resultData;
    }
    return resultData || [];
}

export {
    getAgeLimitedFromGameData,
    getLoggedGamesFromStorage,
    setLoggedGamesToStorage
}